import { useContext } from "react";
import { FaDiscord } from "react-icons/fa";
import { RxDashboard } from "react-icons/rx";
import { Link } from "react-scroll";
import { UserContext } from "../utils/context/UserContext";
import {
  Bars,
  Logo,
  MainButton,
  Nav,
  NavLinkBtn,
  NavMenu,
} from "../utils/styles";
import "../utils/styles/styles.css";
const ASEBotTransparent = require("../assets/ASEBotTransparent.png");

export const Navbar = ({ home }: { home: boolean }) => {
  //   const redirect = () => {
  //     return <Navigate to='/' />
  //   }
  const { user } = useContext(UserContext);
  const discord = () => {
    window.open("http://discord.gg/hgn3PbmWUh");
  };
  const redirect = () => {
    if (user === undefined) {
      window.location.href = `${process.env.REACT_APP_API_URL}/auth/login`;
    } else if (user) {
      window.location.href = `${process.env.REACT_APP_URL}/menu`;
    }
  };
  return home ? (
    <Nav>
      <Bars />
      <Logo src={ASEBotTransparent} alt="logo" style={{ marginLeft: "20px" }} />
      <NavMenu style={{ marginLeft: "100px" }}>
        <Link className="button" activeClass="active" smooth spy to="home">
          Home
        </Link>
        <div style={{ marginLeft: "20px", marginRight: "20px" }} />
        <Link className="button" activeClass="active" smooth spy to="features">
          Features
        </Link>
        <div style={{ marginLeft: "20px", marginRight: "20px" }} />
        <Link className="button" activeClass="active" smooth spy to="pricing">
          Pricing
        </Link>
      </NavMenu>
      <MainButton
        style={{
          width: "auto",
          padding: "4px 25px",
          height: "50px",
          marginTop: "20px",
          alignContent: "center",
          marginRight: "20px",
        }}
        onClick={redirect}
      >
        {user ? (
          <RxDashboard size={20} color="#BFA874" />
        ) : (
          <FaDiscord size={20} color="#BFA874" />
        )}
        {user ? (
          <p
            style={{
              fontSize: "14px",
              marginLeft: "10px",
            }}
          >
            Enter Dashboard
          </p>
        ) : (
          <p
            style={{
              fontSize: "14px",
              marginLeft: "10px",
            }}
          >
            Login with Discord
          </p>
        )}
      </MainButton>
    </Nav>
  ) : (
    <Nav>
      <Bars />
      <Logo src={ASEBotTransparent} alt="logo" style={{ marginLeft: "20px" }} />
      <NavMenu style={{ marginLeft: "100px" }}>
        <NavLinkBtn to="/home">Home</NavLinkBtn>
        <div style={{ marginLeft: "20px", marginRight: "20px" }} />
        <NavLinkBtn to="/home">Features</NavLinkBtn>
        <div style={{ marginLeft: "20px", marginRight: "20px" }} />
        <NavLinkBtn to="/home">Pricing</NavLinkBtn>
      </NavMenu>
      <MainButton
        style={{
          width: "auto",
          padding: "4px 25px",
          height: "50px",
          marginTop: "20px",
          alignContent: "center",
          marginRight: "20px",
        }}
        onClick={redirect}
      >
        {user ? (
          <RxDashboard size={20} color="#BFA874" />
        ) : (
          <FaDiscord size={20} color="#BFA874" />
        )}
        {user ? (
          <p
            style={{
              fontSize: "14px",
              marginLeft: "10px",
            }}
          >
            Enter Dashboard
          </p>
        ) : (
          <p
            style={{
              fontSize: "14px",
              marginLeft: "10px",
            }}
          >
            Login with Discord
          </p>
        )}
      </MainButton>
    </Nav>
  );
};
