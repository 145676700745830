import { Button, Container, Flex, GridButtons, Page, TextArea, Title } from "../utils/styles";
import createTheme from '@mui/material/styles/createTheme';
import green from "@mui/material/colors/green";
import { Grid, ThemeProvider } from "@mui/material";
import { useContext, useState } from "react";
import { HexColorPicker, HexColorInput } from "react-colorful";
import { useEconomicCalendar } from "../utils/hooks/useEconomicCalendar";
import { GuildContext } from "../utils/context/GuildContext";
import Select from 'react-select';
import { BarLoader } from "react-spinners";
import { sendEmbedMessage } from "../utils/api";
const API_URL = process.env.REACT_APP_API_URL;
export const TVWebhookContent = () => {
  const { guild } = useContext(GuildContext);
  const guildId = (guild && guild.id) || '';
  const [channel, setChannel] = useState<string>();
  const { channels, loading, selectedChannel, setSelectedChannel } = useEconomicCalendar(guildId);
  const savePrefix = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const webhook = `${API_URL}/guilds/channel/${channel}/webhook`
    try {
      navigator.clipboard.writeText(webhook)
    } catch (err) {
      console.log(err);
    }
  };

  return (channels && !loading) ? (
    <Page>
      <Container style={{ width: 'auto', maxWidth: '800px', position: 'sticky', left: '25vw', height: "100vh" }}>
        <Title>Generate Webhook</Title>
        <form>
          <div>
            <label htmlFor="webhook">Select Channel</label>
          </div>
          <Select
            name="channelSelect"

            classNamePrefix={"channelSelect"}
            options={channels}
            onChange={(e) => {
              setChannel(e?.id)
            }}
            getOptionLabel={(channels) => `#${channels.name}`}
            getOptionValue={(channels) => channels.id}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: 'black',
                borderRadius: '5px',
                borderColor: '#BFA874',
                color: 'white',
                width: "300px",
                marginBottom: "20px"
              }),
              menu: (base) => ({
                ...base,
                // override border radius to match the box
                borderColor: '#BFA874',
                borderRadius: 0,
                // kill the gap
                marginTop: 0,
                backgroundColor: "black",
                pointerEvents: "all",
                boxShadow: "#BFA874 0px 1px 4px"
              }),
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                text: 'black',
                font: 'black',
                primary25: '#393939',
                primary75: '#BFA874',
                primary50: 'black',
                primary: '#BFA874',
                neutral80: 'white',
                color: 'white',
              }
            })}
          />
          <GridButtons>
            <Button variant="primary" onClick={savePrefix} style={{width: "250px"}}>Generate</Button>
          </GridButtons>
        </form>
      </Container>
    </Page>
  ) : (<Flex justifyContent="center" alignItems="center"><BarLoader color="white" /></Flex>);
}